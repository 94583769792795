import { localStorage, sessionStorage } from '../utils/storage'
import useBrowserMode from '../hooks/useBrowserMode'

const { isBrowser } = useBrowserMode()
const USER_DATA = 'USER_INFO'
const TOKEN_DATA = 'TOKEN_INFO'
const USER_NAME = 'userName'

const findStorage = key => {
	return isBrowser && (localStorage.getItem(key) || sessionStorage.getItem(key))
}

const getUserData = () => {
	return findStorage(USER_DATA)
}

const getTokenData = () => {
	return findStorage(TOKEN_DATA)
}

const getIdTokenData = () => {
	const token = getTokenData()
	return token && token.idToken
}

const getRefreshTokenData = () => {
	const token = getTokenData()
	return token && token.refreshToken
}

const getUserName = () => {
	return findStorage(USER_NAME)
}

const getUserPoints = () => {
	const data = getUserData()
	return data && data.points
}

const destroySession = () => {
	if (isBrowser) {
		localStorage.removeItem(USER_DATA)
		localStorage.removeItem(TOKEN_DATA)
		localStorage.removeItem(USER_NAME)
	}
}

const SessionService = {
	getUserData,
	getTokenData,
	getIdTokenData,
	getRefreshTokenData,
	getUserPoints,
	getUserName,
	destroySession,
}

export default SessionService