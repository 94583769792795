import React, { Component } from "react";
import PropTypes from "prop-types";

class CoreErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    if (
      error.name === "ChunkLoadError" ||
      error.message.startsWith(
        "The result of this StaticQuery could not be fetched"
      )
    ) {
      this.setState({ error });

      window.location.reload();
    } else {
      throw error;
    }
  }

  render() {
    return this.state.error ? null : this.props.children;
  }
}

CoreErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default CoreErrorBoundary;
