import React from 'react'

const initialState = {
	modalActive: null,
}

const ModalContext = React.createContext(initialState)

const reducer = (state, action) => {
	switch (action.type) {
		case 'set-modal':
			return { modalActive: action.modalActive }
		case 'clear-modal':
			return { modalActive: null }
		default:
			return { ...state }
	}
}

function ModalContextProvider(props) {
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const value = { ...state, state, dispatch }

	return (
		<ModalContext.Provider value={value}>
			{props.children}
		</ModalContext.Provider>
	)
}

const ContextConsumer = ModalContext.Consumer

export { ModalContext, ModalContextProvider, ContextConsumer }
